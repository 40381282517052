import React from 'react';
import Link from '/src/components/LocalizedLink';

import Layout from '../../../../components/Layout';

import IconWhatsApp from '../../../../assets/images/svg/whatsapp.inline.svg';
import IconArrowRight from '../../../../assets/images/svg/arrow-right.inline.svg';

import '../../../../assets/styles/pages/terms-and-privacy-page.scss';

const ApplyThankYou = () => {
  return (
    <Layout
      title="Application received | TEFL Iberia"
      description="Many thanks for your application!"
    >
      <div className="terms-and-privacy-page">
        <div className="container">
          <div className="wrapper">
            <h1 className="c-title-42" style={{ textAlign: 'left', marginBottom: 40 }}>
              Application received
            </h1>
            <div className="subsection">
              <p className="subsection__par">Many thanks for your application!</p>
              <p className="subsection__par">
                Your application has been received and we will review it shortly. This typically
                takes under a day (Monday-Friday) and we’ll contact you soon to arrange a video /
                phone / in-person interview.
              </p>
              <p className="subsection__par">
                We will be in touch via email,{' '}
                <b> please check your spam folder if you do not hear from us!</b>
              </p>
              <p className="subsection__par"> The ILA Team</p>
              <Link to="/" className="c-btn c-btn--red mt-50">
                Return to the home page
                <IconArrowRight />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ApplyThankYou;
